<template>
  <div class="transition-detail-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center">

      <template #modal-title>
        <h1>{{ $t('transitions.details') }}</h1>
      </template>

      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-6">
              <h5>{{ $t("common.start_city") }}</h5>
              {{ record.start_cluster.name }}
            </div>
            <div class="col-md-6">
              <h5>{{ $t("common.end_city") }}</h5>
              {{ record.end_cluster.name }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-4">
              <h5>{{ $t("common.pickup_since") }}</h5>
              {{ record.start_date | formatDate }}
            </div>
            <div class="col-md-4">
              <h5>{{ $t("common.arrival_until") }}</h5>
              {{ record.end_date | formatDate }}
            </div>
            <div class="col-md-4">
              <h5>{{ $t("common.latest_reservation_date") }}</h5>
              {{ record.latest_reservation_date | formatDate }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-4">
              <h5>{{ $t("multiples.vehicle_class.one") }}</h5>
              {{ record.vehicle_class.name }}
            </div>
            <div class="col-md-4">
              <h5>{{ $t("multiples.vehicle.one") }}</h5>
              {{ record.vehicle.name }}
            </div>
            <div class="col-md-4">
              <h5>{{ $t("common.engine") }}</h5>
              {{ record.engine_description }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-6">
              <h5>{{ $t("common.retention") }}</h5>
              {{ record.vehicle_class.retention | formatPrice }}{{ $t('unit.euro') }}
            </div>
            <div class="col-md-6">
              <h5>{{ $t("common.deposit") }}</h5>
              {{ record.vehicle_class.deposit | formatPrice }}{{ $t('unit.euro') }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-6">
              <h5>{{ $t("common.contingent") }}</h5>
              {{ record.remaining_contingent }} / {{ record.contingent }}
            </div>
            <div class="col-md-6">
              <h5>{{ $t("common.price") }}</h5>
              {{ record.price_current | formatPrice }}{{ $t('unit.euro') }} /
              {{ record.price_total | formatPrice }}{{ $t('unit.euro') }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.mediation_via_flipcar") }}</h5>
              {{ record.mediation_via_flipcar | formatBoolean }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.mediation_via_prologistics") }}</h5>
              {{ record.mediation_via_prologistics | formatBoolean }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.mediation_via_trailer") }}</h5>
              {{ record.mediation_via_trailer | formatBoolean }}
            </b-col>
          </b-row>
        </li>
        <li v-if="record.notes" class="list-group-item">
          <h5>{{ $t("common.notes") }}</h5>
          {{ record.notes }}
        </li>
      </ul>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getTransition} from "@/services/transitions";
import MetadataList from "@/components/metadata_list";

export default {
  name: "TransitionDetailModal",
  components: {MetadataList},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    }
  },
  data() {
    return {
      visible: false,
      record: {}
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value && this.id > 0) this.record = await getTransition(this.id) // TODO: catch
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>